class Layer
	layers : null
	webgl : false
	preload : () ->
	translate_center : () ->
	constructor : (@size) ->
	reset : () ->
	init : (@layers, @image) ->
		@setup()
	setup : () ->
	focus : () ->
	defocus : () ->

	draw : (delta) ->
		@image.fill 255, 0,255
		@image.rect 0,0,@size, @size
	pan : (p, delta) ->
		# console.log p
	panstart : (p) ->
	pixel : (p) ->
		p.x = p.x * @size
		p.y = p.y * @size
		p
	press : (p) ->

	pressup : (p) ->
	panend : (p) ->
	tap : (p) ->
		# console.log p
	pos_to_window : (p) ->
		p.mult createVector(innerWidth, innerHeight)
		p.x = constrain (p.x - @window.pos.x), 0, @window.size.x
		p.y = constrain (p.y - @window.pos.y), 0, @window.size.y
		# console.log @window.sizeA
		# p.div @window.size
		p.x = p.x / @window.size.x
		p.y = p.y / @window.size.y
		p
	# draw_border : (win, sw = 3, p = 3) ->
	# 	noFill()
	# 	stroke 0
	# 	rect win.pos.x - p, win.pos.y - p, win.size.x + p + p, win.size.y + p + p
	# draw_window : (img, win) ->
	# 	image img, win.pos.x, win.pos.y, win.size.x, win.size.y
	# blend : () ->
	# 	@draw_window @image, @window
	pos_inside : (pos, padding = 0) ->
		collidePointRect pos.x, pos.y, padding, padding, @size - padding, @size - padding

	constrain_point : (p, w, h, x = 0, y = 0) ->
		p.x = constrain p.x, x, (x + w)
		p.y = constrain p.y, y, (y + h)
		p

	separate_circles : (pos, diameter, collider_pos, collider_diameter) ->
		if collideCircleCircle pos.x, pos.y, diameter, collider_pos.x, collider_pos.y, collider_diameter
			v = p5.Vector.sub pos, collider_pos
			v.normalize()
			v.mult (collider_diameter + diameter) * 0.5
			v.add collider_pos
			v
		else
			pos

	wrap_point : (p, w, h, x = 0, y = 0) ->
		if p.x < 0
			p.x = w
		if p.x > w
			p.x = 0
		if p.y < 0
			p.y = h
		if p.y > h
			p.y = 0
		p.x = constrain p.x, x, (x + w)
		p.y = constrain p.y, y, (y + h)
		p
